import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'
import auth0Client from '../../helpers/auth0';

import {
    ToastContainer,
} from 'react-toastify';


import Callback from '../../helpers/auth0-callback';
import {connect} from "react-redux";

const Auth = lazy(() => import('../../Pages/Auth'));
const Forms = lazy(() => import('../../Pages/Forms'));
const Emails = lazy(() => import('../../Pages/Emails'));
const Media = lazy(() => import('../../Pages/Media'));
const Deployments = lazy(() => import('../../Pages/Deployments'));
const Databases = lazy(() => import('../../Pages/Databases'));



const _SecuredRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                auth0Client.checkSession();
                return (rest.isAuthenticated) ? (
                //return ((rest.expiresAt/1000)) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/authentication/login",
                            state: {from: props.location}
                        }}
                    />
                )
            }
            }
        />
    );
    return;
}

const mapStateToProp = state => ({
    isAuthenticated: state.Auth.isAuthenticated
});

const SecuredRoute = connect(
    mapStateToProp
)
(_SecuredRoute);

const AppMain = () => {
    return (
        <Fragment>

            {/* OAuth Callback */}

            <Route path="/callback" component={Callback}/>

            {/* Authentication */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                        <h6 className="mt-5">
                            Wird geladen
                            <small>Bitte warten Sie einen Augenblick!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/authentication" component={Auth}/>
            </Suspense>

            {/* Forms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                        <h6 className="mt-5">
                            Formulare werden geladen
                            <small>Bitte warten Sie einen Augenblick!</small>
                        </h6>
                    </div>
                </div>
            }>
                <SecuredRoute path="/forms" component={Forms}/>
            </Suspense>

            {/* Forms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                        <h6 className="mt-5">
                            E-Mails werden geladen
                            <small>Bitte warten Sie einen Augenblick!</small>
                        </h6>
                    </div>
                </div>
            }>
                <SecuredRoute path="/emails" component={Emails}/>
            </Suspense>

            {/* Forms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                        <h6 className="mt-5">
                            Medien werden geladen
                            <small>Bitte warten Sie einen Augenblick!</small>
                        </h6>
                    </div>
                </div>
            }>
                <SecuredRoute path="/media" component={Media}/>
            </Suspense>

            {/* Forms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                        <h6 className="mt-5">
                            Deployments werden geladen
                            <small>Bitte warten Sie einen Augenblick!</small>
                        </h6>
                    </div>
                </div>
            }>
                <SecuredRoute path="/deployments" component={Deployments}/>
            </Suspense>

            {/* Forms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                        <h6 className="mt-5">
                            Datenbanken werden geladen
                            <small>Bitte warten Sie einen Augenblick!</small>
                        </h6>
                    </div>
                </div>
            }>
                <SecuredRoute path="/databases" component={Databases}/>
            </Suspense>



            <Route exact path="/" render={() => (
                <Redirect to="/authentication/login"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    );
};




export default AppMain;