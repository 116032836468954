import auth0 from 'auth0-js';
import {store} from '../index';
import {requestLogout, receiveLogin} from "../reducers/Auth";

class Auth {
    constructor() {
        this.auth0 = new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            clientID: process.env.REACT_APP_AUTH0_CLIENTID,
            redirectUri: process.env.REACT_APP_AUTH0_REDIRECTURI,
            responseType: process.env.REACT_APP_AUTH0_RESPONSETYPE,
            scope: process.env.REACT_APP_AUTH0_SCOPE,
            language: process.env.REACT_APP_AUTH0_LANGUAGE,

        });

        this.getProfile = this.getProfile.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    getProfile() {
        return this.profile;
    }

    getIdToken() {
        return this.idToken;
    }

    checkSession() {
        this.auth0.checkSession({},(err, authResult) => {
            console.log(err,authResult);
            if(err !== null) {
                store.dispatch(requestLogout());
            }
            else {
                store.dispatch(receiveLogin(authResult));
            }
        })
    }

    isAuthenticated() {
        //return new Date().getTime() < this.expiresAt;
        return new Date().getTime() < localStorage.expiresAt;
    }

    signIn() {
        this.auth0.authorize();
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (err) return reject(err);
                if (!authResult || !authResult.idToken) {
                    return reject(err);
                }
                //console.log(authResult);
                //this.idToken = authResult.idToken;
                //localStorage.idToken = authResult.idToken;
                //this.profile = authResult.idTokenPayload;
                //localStorage.profile = authResult.idTokenPayload;
                // set the time that the id token will expire at
                //this.expiresAt = authResult.idTokenPayload.exp * 1000;
                //localStorage.expiresAt = authResult.idTokenPayload.exp * 1000;
                resolve(authResult);
            });
        })
    }

    signOut() {
        // clear id token, profile, and expiration
        //this.idToken = null;
        //this.profile = null;
        //this.expiresAt = null;
        localStorage.idToken = null;
        //this.profile = authResult.idTokenPayload;
        localStorage.profile = null;
        // set the time that the id token will expire at
        //this.expiresAt = authResult.idTokenPayload.exp * 1000;
        localStorage.expiresAt = null;
    }
}

const auth0Client = new Auth();

export default auth0Client;