import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './auth0';
import {receiveLogin, loginError} from "../reducers/Auth";
import {connect} from "react-redux";
import axios from "axios";


class Callback extends Component {
    async componentDidMount() {
        const result = await auth0Client.handleAuthentication().catch((error) => { this.props.loginError(error) });
        console.log(result);
        this.props.receiveLogin(result);
        this.props.history.replace('/forms/overview');
    }

    render() {
        return (
            <p>Bitte warten...</p>
        );
    }
}

const mapStateToProp = state => ({
    //oauth: state.oauth
});

const mapDispatchToProps = dispatch => ({
    loginError: error => dispatch(loginError(error)),
    receiveLogin: result => dispatch(receiveLogin(result)),
});

export default withRouter(
    connect(
        mapStateToProp,
        mapDispatchToProps
    )
    (Callback)
);