import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import reducers from '../reducers';
import thunk from "redux-thunk";
import {loadState, saveState} from "../helpers/localState";


export default function configureStore() {
    const { devToolsExtension = null } = global.window || {};
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        combineReducers({
            ...reducers,
        }),

        loadState(),

        composeEnhancers(
            applyMiddleware(thunk),
            //devToolsExtension(),
        )
    );

    store.subscribe(() => {
        saveState(store.getState());
    });

    return store;
}