import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv-flow';

import * as serviceWorker from './serviceWorker';

//import { HashRouter } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './DemoPages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

import { ConfigContext} from "./config/config-context";

dotenv.config();

const onRedirectCallback = appState => {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const store = configureStore();
export {store};
const rootElement = document.getElementById('root');

const config = {
    api_base: process.env.REACT_APP_API_BASE,
}

export const context = config;

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <ConfigContext.Provider value={config}>
                <BrowserRouter>
                    <Component />
                </BrowserRouter>
            </ConfigContext.Provider>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./DemoPages/Main', () => {
        const NextApp = require('./DemoPages/Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();