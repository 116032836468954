import auth0Client from "../helpers/auth0";
import axios from 'axios';

export const LOGIN_REQUEST = 'USER/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USER/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'USER/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'USER/LOGOUT_REQUEST';


export const requestLogin = () => ({
    type: LOGIN_REQUEST,
});

export const receiveLogin = (authInfo) => ({
    type: LOGIN_SUCCESS,
    authInfo
});

export const loginError = (message) => ({
    type: LOGIN_ERROR,
    message
});

export const requestLogout = () => ({
    type: LOGOUT_REQUEST,
});


export default function reducer(state = {
    isFetching: false,
    error: null,
    isAuthenticated: false,
    token: null,
    expiresAt: 0,
    profile: null,

}, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            auth0Client.signIn();
            return {
                ...state,
                isFetching: true,
                error: null
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                profile: action.authInfo.idTokenPayload,
                token: action.authInfo.idToken,
                expiresAt: action.authInfo.idTokenPayload.exp * 1000,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                profile: {},
                token: null,
                expiresAt: 0,
                error: action.error
            };
        case LOGOUT_REQUEST:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                profile: {},
                token: null,
                expiresAt: 0,
                error: null
            };


    }
    return state;
}
